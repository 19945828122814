import React from 'react'
import styled from 'styled-components'

import { Layout } from '../../components/layout'
import { Footer } from '../../components/footer'
import { Teaser } from '../../components/teaser'

import { Image } from '../../components/common/image'
import { Grid, Row, Col } from '../../components/common/grid'
import { Space } from '../../components/common/space'
import { H4 } from '../../components/common/heading'

const List = styled.ul`
  margin: 0;
`

const ListItem = styled.li`
  color: #69707b;
  text-align: justify;
  margin-bottom: 10px;
`

const title = 'Chimpanzee Proteinriegel'
const description = 'Vegan, glutenfrei und ohne Geschmacksverstärker'
const keywords = ''

const PropteinRiegel = () => (
  <Layout title={title} description={description} keywords={keywords}>
    <Teaser title={title} description={description} imageName="proteinriegel" />

    <Grid>
      <Row centered>
        <Col xs={12} md={9}>
          <div>
            <Space bottom={20}>
              <H4>Chimpanzee Proteinriegel (45g)</H4>
            </Space>
            <p>
              Die BIO zertifizierten Proteinriegel von Chimpanzee sind vegan,
              glutenfrei und ohne Geschmacksverstärker. Sie enthalten
              pflanzliches Eiweiß aus Vollkornreis und Mandelpaste und schmecken
              einfach fantastisch. Sie sind auch für Veganer und Vegetarier sehr
              zu empfehlen, die eine erhöhte, zusätzliche Proteinzufuhr
              benötigen.
            </p>

            <Space top={50} bottom={20}>
              <H4>Geschmacksrichtungen</H4>
            </Space>

            <List>
              <ListItem>Erdnussbutter (Vegan)</ListItem>
              <ListItem>Datteln & Vanille (Vegan)</ListItem>
              <ListItem>Banane (Vegan)</ListItem>
            </List>
          </div>
        </Col>
      </Row>

      <Space top={50} bottom={20}>
        <Row centered>
          <Col xs={12} md={9}>
            <H4>Produktbeispiele</H4>
          </Col>
        </Row>
      </Space>

      <Row centered>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="proteinriegel_banana" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="proteinriegel_dates" />
        </Col>
        <Col xs={12} sm={4} md={3} bottom={50}>
          <Image name="proteinriegel_peanut" />
        </Col>
      </Row>
    </Grid>

    <Footer bottom={50} />
  </Layout>
)

export default PropteinRiegel
